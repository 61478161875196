import { render, staticRenderFns } from "./Card.vue?vue&type=template&id=df73a826&scoped=true&"
import script from "./Card.vue?vue&type=script&lang=js&"
export * from "./Card.vue?vue&type=script&lang=js&"
import style0 from "./Card.vue?vue&type=style&index=0&id=df73a826&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "df73a826",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AsyncImage: require('/var/www/html/nod.ba_nuxt/components/AsyncImage.vue').default})
