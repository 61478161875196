
    export default {
        data() {
            return {
                // search: '',
                image: null,
                image_loading: true,
                image_styles: {},
            };
        },
        props: [ 'image_path', 'element_class', 'styles', 'is_async'],
        watch: {
            image_path: function (newImage, oldImage) {
                if(newImage !== oldImage){
                    this.setImageStyles();
                }
            }
        },
        created() {
            if(this.is_async){

                // axios.get(`/image?imagePath=${this.image_path}`)
                // .then(response => {
                    // this.image = response.data, this.setImageStyles()
                    // this.image_loading = false;
                // })

                this.setImageStyles();
                this.image_loading = false;
            } else {
                this.image_styles = this.styles;
                this.image = this.image_path;
                // this.image_styles['background-image'] = 'url("' + window.axios.defaults.baseURL + '/image?imagePath=' + this.image_path + '")';
                this.image_styles['background-image'] = 'url("' + this.image_path + '")';
                this.image_loading = false;
            }

            
        },
        mounted() {
            // console.log('Component mounted.')

            var lazyloadImages;    
            if ("IntersectionObserver" in window) {
                lazyloadImages = document.querySelectorAll(".lazy");
                var imageObserver = new IntersectionObserver(function(entries, observer) {
                entries.forEach(function(entry) {
                    if (entry.isIntersecting) {
                    var image = entry.target;
                    image.classList.remove("lazy");
                    imageObserver.unobserve(image);
                    }
                });
                });

                lazyloadImages.forEach(function(image) {
                imageObserver.observe(image);
                });
            } else {  
                var lazyloadThrottleTimeout;
                lazyloadImages = document.querySelectorAll(".lazy");
                
                function lazyload () {
                if(lazyloadThrottleTimeout) {
                    clearTimeout(lazyloadThrottleTimeout);
                }    

                lazyloadThrottleTimeout = setTimeout(function() {
                    var scrollTop = window.pageYOffset;
                    lazyloadImages.forEach(function(img) {
                        if(img.offsetTop < (window.innerHeight + scrollTop)) {
                        img.src = img.dataset.src;
                        img.classList.remove('lazy');
                        }
                    });
                    if(lazyloadImages.length == 0) { 
                    document.removeEventListener("scroll", lazyload);
                    window.removeEventListener("resize", lazyload);
                    window.removeEventListener("orientationChange", lazyload);
                    }
                }, 20);
                }

                document.addEventListener("scroll", lazyload);
                window.addEventListener("resize", lazyload);
                window.addEventListener("orientationChange", lazyload);
            }
        },
        methods: {
            setImageStyles(){
                this.image_styles = this.styles;
                this.image_styles['background-image'] = 'url("' + this.$axios.defaults.baseURL + '/image?imagePath=' + this.image_path +'")';
            }
        }
    }
