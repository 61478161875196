
    export default {
        props: ['education'],
        mounted() {
            // console.log('Component mounted.')
        },
        computed: {
            get_url: () => (title) => {
                // title = title.toLowerCase().replace(/ž/g, "z").replace(/č/g, "c").replace(/đ/g, "d").replace(/š/g, "s").replace(/ć/g, "c").replace("?", ".").replace("/", ",")
                // return '/edukacija/' + title.toLowerCase().replace(/ /g, '_');
                return '/edukacija/'.this.education.slug;
                // .replace(/[^\w-]+/g, '');
            },
        },
        methods: {
            toEducation(education){
                if (this.$route.params.education_title !== education.title){
                    this.$router.push({ name: 'education', params: { education_title: education.title } })
                }
            },

            getPlaceholderImageFromLink(yt_link){
                const parts = yt_link.split('/');
                const linkId = parts[parts.length - 1];
                return `http://img.youtube.com/vi/${linkId}/mqdefault.jpg`;

                // http://img.youtube.com/vi/<insert-youtube-video-id-here>/hqdefault.jpg
                // http://img.youtube.com/vi/<insert-youtube-video-id-here>/0.jpg
                // http://img.youtube.com/vi/<insert-youtube-video-id-here>/1.jpg
                // http://img.youtube.com/vi/<insert-youtube-video-id-here>/2.jpg
                // http://img.youtube.com/vi/<insert-youtube-video-id-here>/3.jpg

                
            }
        }
    }
