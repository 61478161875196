
    export default {
        props: ['full_width', 'alignment'],
        data() {
            return {
                advert: null,
                advert_image: null,
                advert_link: null,
                loading: true,
                has_banners: false,
            } 
        },
        computed: {
            get_advert_classes:(data) => {
                const is_loading = data.loading;
                const full_width = data.full_width;
                const alignment = data.alignment;

                var classes = '';
                if(full_width){
                    classes += 'full-width';
                }

                if(is_loading){
                    classes += ' loading';
                }

                classes += " " + alignment;

                return classes;
            }
        },
        created() {
            this.fetchAdvert();
        },
        mounted() {},
        methods: {

            async fetchAdvert(){
                this.loading = true;
                const url = (this.full_width) ? '/ads?full_width=true' : '/ads';
                const result = await this.$axios.get(url)

                // if(result.data.data.length > 0){
                if(result.data.data.length !== 0){
                    this.has_banners = true;
                    this.advert = result.data.data,
                    this.advert_link = result.data.data.link,
                    this.fetchImage()
                }
                
                
                // .then((response) => {
                //     this.advert = response.data.data,
                //     this.advert_link = response.data.data.link,
                //     this.fetchImage()
                // })
                // .catch(error => {
                //     // console.error("There was an error!", error);
                // });
            },


            fetchImage(){
                // axios.get(`image?imagePath=${this.advert.image}`)
                // .then((response) => {
                //     this.advert_image = response.data.data,
                //     this.loading = false
                // })
                // .catch(error => {
                //     // console.error("There was an error!", error);
                // });
                this.loading = false;
                this.advert_image = this.$axios.defaults.baseURL + '/image?imagePath=' + this.advert.image;
            }
        }
    }
