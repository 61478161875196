
    export default {
        data(){
            return {
                news: [],
                current_news: null,
                current_index: 0,
                can_go_prev: false,
                can_go_next: true
            }
        },
        computed: {
            get_url: () => (title) => {
                title = title.toLowerCase().replace(/ž/g, "z").replace(/č/g, "c").replace(/đ/g, "d").replace(/š/g, "s").replace(/ć/g, "c").replace("?", ".")
                return encodeURI('/novost/' + title.toLowerCase().replace(/ /g, '_'));
                // .replace(/[^\w-]+/g, '');
            },
        },
        created(){
            this.fetchNews();
        },
        mounted() {
        },
        methods: {
            async fetchNews(){
                const results = await this.$axios.get("/news?page=1&results=4&order=desc");
                this.news = results.data.results;
                this.current_news = results.data.results[0];
            },

            prev(){
                if(this.news[this.current_index - 1]){
                    this.current_news = this.news[this.current_index - 1];
                    this.current_index -= 1;

                    this.checkSteps();
                }
            },

            next(){
                if(this.news[this.current_index + 1]){
                    this.current_news = this.news[this.current_index + 1];
                    this.current_index += 1;

                    this.checkSteps();
                }
            },

            checkSteps(){
                if(this.current_index > 0){
                    this.can_go_prev = true;
                } else {
                    this.can_go_prev = false;
                }

                if(this.current_index + 1 < this.news.length){
                    this.can_go_next = true;
                } else {
                    this.can_go_next = false;
                }
            }
        }
    }
