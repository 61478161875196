
  import Newest from "../components/News/Newest.vue";
  import Skeleton from "../components/Educations/Skeleton.vue";
  import MostRead from "../components/News/MostRead.vue";
  
  export default {
    // metaInfo () {
    //     return {
    //         // titleTemplate: this.$route.params.news_title,
    //         meta: [
    //             {name: 'title', content: 'Neformalno Obrazovanje Danas',},
    //             {name: 'description', content: 'NOD platforma nastala je sa ciljem da na jednom mjestu pruži sve relevantne informacije o neformalnom obrazovanju u Bosni i Hercegovini i da na brz i jednostavan način svojim korisnicima pruži mogućnost korištenja benefita neformalnog obrazovanja.'},
                

    //             // {name: 'og:url', content: 'https://nod.ba'},
    //             {name: 'og:type', content: 'website'},
    //             {name: 'og:title', content: 'Neformalno Obrazovanje Danas'},
    //             {name: 'og:description', content: 'NOD platforma nastala je sa ciljem da na jednom mjestu pruži sve relevantne informacije o neformalnom obrazovanju u Bosni i Hercegovini i da na brz i jednostavan način svojim korisnicima pruži mogućnost korištenja benefita neformalnog obrazovanja.'},
    //             {name: 'og:image', content: 'https://nod.ba/social.png'},
    //             { hid: 'og-image', property: 'og:image', content: 'https://nod.ba/social.png' },


    //             {name: 'twitter:title', content: 'Neformalno Obrazovanje Danas'},
    //             {name: 'twitter:description', content: 'NOD platforma nastala je sa ciljem da na jednom mjestu pruži sve relevantne informacije o neformalnom obrazovanju u Bosni i Hercegovini i da na brz i jednostavan način svojim korisnicima pruži mogućnost korištenja benefita neformalnog obrazovanja.'},        
    //         ],
    //     }
    // },
    head() {
        return {
            title: "Neformalno Obrazovanje Danas",
            meta: [
                {
                    hid: 'og-title',
                    property: 'og:title',
                    content: "Neformalno Obrazovanje Danas",
                },
                {
                    hid: 'og-url',
                    property: 'og:url',
                    content: 'https://nod.ba/',
                },
                {
                    hid: 'og-image',
                    property: 'og:image',
                    content: 'https://nod.ba/social.png'
                },
                {
                    hid: 'description',
                    property: 'description',
                    content: 'NOD platforma nastala je sa ciljem da na jednom mjestu pruži sve relevantne informacije o neformalnom obrazovanju u Bosni i Hercegovini i da na brz i jednostavan način svojim korisnicima pruži mogućnost korištenja benefita neformalnog obrazovanja.'
                },
                {
                    hid: 'og-description',
                    property: 'og:description',
                    content: 'NOD platforma nastala je sa ciljem da na jednom mjestu pruži sve relevantne informacije o neformalnom obrazovanju u Bosni i Hercegovini i da na brz i jednostavan način svojim korisnicima pruži mogućnost korištenja benefita neformalnog obrazovanja.'
                },
                // other meta
            ],
        };
    },
    data: function () {
        return {
            newest_news: [],
            loading_newest_news: true,  

            newest_educations: [],
            loading_newest_educations: true,

            partners: [],
            loading_partners: true
        }
    },
    components: {
        Newest,
        Skeleton,
        MostRead
    },
    created(){
        this.fetchData();
    },
    beforeMount(){},
    mounted() {},
    methods: {

        async fetchData(){
            this.loading_newest_educations = true;
            const results = await this.$axios.get("/educations?page=1&results=9&order=desc")

            this.newest_educations = results.data.results;
            this.loading_newest_educations = false;

            this.loading_partners = true;
            const partners_results = await this.$axios.get('/partners');
            this.partners = partners_results.data.data;

            this.loading_partners = false;
        }

    }
  }
  