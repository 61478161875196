
    import axios from 'axios';

    export default {
        data(){
            return{
                loading: true,
                news: [],
            }
        },
        computed: {
            get_url: () => (title) => {
                title = title.toLowerCase().replace(/ž/g, "z").replace(/č/g, "c").replace(/đ/g, "d").replace(/š/g, "s").replace(/ć/g, "c").replace("?", ".")
                return encodeURI('/novost/' + title.toLowerCase().replace(/ /g, '_'));
                // .replace(/[^\w-]+/g, '');
            },
        },
        created() {
            this.fetchNews();
        },
        mounted() {},
        methods: {
            async fetchNews(){
                this.loading = true;
                const results = await this.$axios.get("/news")

                this.news = results.data.data.slice(0,5);
                this.loading = false;
                // .then(response => {
                //     this.news = response.data.data.slice(0, 5);
                //     this.loading = false;
                // })
                // .catch(error => {
                //     // console.error("There was an error!", error);
                // });
            },

            toNewsPage(news){
                this.$router.push({ name: 'news_article', params: { news_title: news.title }, query: { timestamp: Date.now() } })
            }
        }
    }
