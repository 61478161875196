
    export default {
        data(){
            return{
                loading: true,
                news: [],
            }
        },
        created() {
            this.fetchNews();
        },
        computed: {
            get_url: () => (title) => {
                title = title.toLowerCase().replace(/ž/g, "z").replace(/č/g, "c").replace(/đ/g, "d").replace(/š/g, "s").replace(/ć/g, "c").replace("?", ".")
                return encodeURI('/novost/' + title.toLowerCase().replace(/ /g, '_'));
                // .replace(/[^\w-]+/g, '');
            },
        },
        mounted() {},
        methods: {
            async fetchNews(){
                this.loading = true;
                // const results = await this.$axios.get("/news?page=1&results=8&order=desc")
                const results = await this.$axios.get("/news?page=1&results=6&order=desc")

                this.news = results.data.results;
                this.loading = false;
                // .then(response => {
                //     this.news = response.data.results;
                //     this.loading = false;
                // })
                // .catch(error => {
                //     // console.error("There was an error!", error);
                // });
            },

            toNewsPage(news){
                //if (this.$route.params.news_title !== news.title){
                    this.$router.push({ name: 'news_article', params: { news_title: news.title }, query: { timestamp: Date.now() } })
                //}
            }
        }
    }
