
    import validator from 'validator';

    export default {
        data(){
            return {
                newsletter_email: '',
                can_subscribe: false,
                message: null,
            }
        },
        watch: {
            newsletter_email: function (newVal, oldVal) {
                const isEmail = validator.isEmail(newVal); //=> true
                if(isEmail){
                    this.can_subscribe = true;
                } else {
                    this.can_subscribe = false;
                }
            }
        },
        mounted() {
            // console.log('Component mounted.')
        },
        methods: {
            subscribe(){
                const t = this;

                if(this.can_subscribe){
                    this.$axios.post('/newsletter/subscribe', { email: this.newsletter_email })
                    .then(response => {
                        this.message = 'Uspješno ste se pretplatili na naš newsletter!';
                        this.newsletter_email = '';
                        this.can_subscribe = false;
                    }).catch((error) => {
                        const errorCode = error.response.status;
                        // console.log(errorCode);

                        if(errorCode === 400){
                            t.setMessage('Došlo je do greške. Pokušajte ponovo.');
                        }

                        if(errorCode === 422){
                            t.setMessage('Već ste se preplatili na naš newsletter.')
                        }

                        this.newsletter_email = '';
                        this.can_subscribe = false;
                    });
                }
                
            },

            setMessage(text){
                this.message = text;
            },

            dismissMessage(){
                this.message = null;
            }
        }
    }
